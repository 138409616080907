<script>
import { isComponent } from '@/v-shop/autoloader'
import checkoutMixin from '../checkout-mixin'

export default {
	lang: 'shop',
	mixins: [checkoutMixin],
	data() {
		return {
			forms: [],
			selected: null,
			submittedFormKey: null,
			giftCardCodes: [''],
			giftCardsEnabled: false,
		}
	},
	methods: {
		async submitNoCode() {
			await this.checkout.stepSubmit({ noCode: true })
		},
		async submitForm() {
			let payload = {}

			let form = this.forms.find((f) => f.key === this.selected)
			if (
				form?.key &&
				form.value &&
				Object.values(form.value).length &&
				Object.values(form.value).every(Boolean)
			) {
				payload.key = form.key
				payload.value = form.value
			}

			let cleanGiftCardCodes = this.giftCardCodes.map((x) => x.trim()).filter(Boolean)
			if (cleanGiftCardCodes.length) {
				payload.giftCardCodes = cleanGiftCardCodes
				this.giftCardCodes = cleanGiftCardCodes
			} else {
				this.giftCardCodes = ['']
			}

			await this.checkout.stepSubmit(payload)
			if (form) {
				this.$nextTick(() => {
					this.submittedFormKey = form.key
				})
			}
		},
	},
	mounted() {
		this.giftCardsEnabled = this.checkout.stepViewData?.giftCardsEnabled || false

		const forms = [
			{
				component: 'CheckoutDiscountForm-couponCode',
				data: null,
				key: 'couponCode',
				value: {},
			},
		]

		for (let customDiscountOption of this.checkout.stepViewData?.customDiscountsOptions || []) {
			let component = `CheckoutDiscountForm-${customDiscountOption.key}`
			if (!isComponent(component)) continue
			forms.push({
				component,
				data: customDiscountOption.data,
				key: customDiscountOption.key,
				value: {},
			})
		}

		let initialPayload = this.checkout.stepsData.discount.payload || null
		if (initialPayload?.giftCardCodes?.length && this.giftCardsEnabled) {
			this.giftCardCodes = initialPayload.giftCardCodes
		}

		if (initialPayload?.key) {
			let form = forms.find((f) => f.key === initialPayload.key)
			if (form) {
				form.value = initialPayload.value
				this.selected = form.key
			}
		}

		if (!this.selected) this.selected = forms[0].key
		this.forms = forms
	},
}
</script>

<template>
	<div>
		<Validator v-if="giftCardsEnabled" :validation="checkout.validation">
			<CheckoutDiscountForm-giftCardCode class="w100 pb-6" v-model="giftCardCodes" />
		</Validator>
		<v-divider class="mb-6" v-if="forms.length > 1" />
		<div class="mb-4 d-flex align-center" v-if="forms.length > 1">
			<v-icon class="mr-2">mdi-ticket</v-icon>
			<b>Cupones de Descuento</b>
		</div>
		<div :class="{ 'form-boxes-container': forms.length > 1 }">
			<v-radio-group v-model="selected" class="font-1 ma-0 pa-0" hide-details>
				<div
					v-for="form of forms"
					:key="form.key"
					:class="{
						'form-box--selected': forms.length > 1 && selected == form.key,
						'form-box form-box--selectable': forms.length > 1,
					}"
					@click="selected = form.key"
				>
					<div class="d-flex align-start">
						<v-radio v-if="forms.length > 1" :value="form.key" />
						<Validator
							:validation="
								submittedFormKey == form.key && selected == form.key ? checkout.validation : {}
							"
						>
							<component
								class="w100"
								:is="form.component"
								:selected="selected == form.key"
								:data="form.data"
								v-model="form.value"
							/>
						</Validator>
					</div>
				</div>
			</v-radio-group>
		</div>
		<div class="d-flex flex-column flex-sm-row pt-6">
			<Button color="link" @click="submitNoCode()" :block="$b.m" class="mb-2 mb-sm-0">
				No tengo cupón <v-icon right>mdi-chevron-right</v-icon>
			</Button>
			<v-spacer class="d-none d-sm-block" />
			<Button color="success" @click="submitForm()" :block="$b.m" class="mb-2 mb-sm-0">
				<v-icon left>mdi-check</v-icon> Aplicar
			</Button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.form-boxes-container {
	border-radius: 8px;
	border: 1px solid #ccc;
	overflow: hidden;
}

.form-box {
	background-color: #fff;
	padding: 24px 24px 24px 12px;
	cursor: pointer;
	&:not(:last-child) {
		border-bottom: 1px solid #bbb;
	}

	&:hover {
		background-color: #f9f9f9;
	}

	&--selectable {
		border-left: 4px solid #ccc;
		&:hover {
			border-left: 4px solid #aaa;
		}
	}

	&--selected {
		background-color: #f5f5f5 !important;
		border-left: 4px solid var(--link) !important;
	}

	.v-radio {
		margin-bottom: 0 !important;
	}
}
</style>

